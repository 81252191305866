'use client'

import Link from 'next/link'
import React, { useEffect } from 'react'
import Button from '../../../libs/helios/button/src/lib/helios-button'
import { useRouter } from 'next/navigation'

export default function NotFound() {
  const router = useRouter()
  useEffect(() => {
    router.push('/')
  }, [])
  return <></>
}
